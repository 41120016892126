import PrimaryButton from 'components/form/button/PrimaryButton';
import DraggablePopup from 'components/widgets/draggable-popup/DraggablePopup';
import './walletVoucherDetailsUseCode.scss';
import useWalletCouponOnline from 'hooks/wallet/useWalletCouponOnline';
import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import useToast from 'components/widgets/toast/useToast';
import { FormattedMessage } from 'react-intl';

interface WalletVoucherDetailsUseCodeProps {
    offerId: number;
    shouldShow: boolean;
    onClose: () => void;
}

const WalletVoucherDetailsUseCode = ({ offerId, shouldShow, onClose }: WalletVoucherDetailsUseCodeProps) => {
    const { useOnline: getOnlineCode, isLoading } = useWalletCouponOnline();
    const { triggerToast } = useToast({ toastId: 'get-online-code' });

    const onConfirm = async () => {
        if (!offerId) {
            triggerToast('Offre inconnue!', 'error');

            return;
        }

        const result = await getOnlineCode(offerId);

        if (!result) {
            return;
        }

        if (!result?.code) {
            triggerToast('Le coupon a déjà été utilisé!', 'error');

            return;
        }

        onClose?.();
    };

    return (
        <DraggablePopup
            className="wallet-use-code"
            show={shouldShow}
            onClose={onClose}
        >
            <h4><FormattedMessage id="wallet-voucher-details.online.get-code.title" /></h4>
            <h5><FormattedMessage id="wallet-voucher-details.online.get-code.body" /></h5>

            <PrimaryButton onClick={onConfirm} isSuccess>
                {isLoading
                    ? <Loader fill={LoaderInButtonFillColor} />
                    : <FormattedMessage id="wallet-voucher-details.online.get-code.confirm" />
                }
            </PrimaryButton>
            <PrimaryButton onClick={onClose} isDanger>
                <FormattedMessage id="wallet-voucher-details.online.get-code.cancel" />
            </PrimaryButton>
        </DraggablePopup>
    );
};

export default WalletVoucherDetailsUseCode;
